@import "./variables.scss";



    .modal-container {
        position: relative !important;
        top: 50% !important;
        left: 50% !important;
        transform: translate(-50%, -50%) !important;
        width: 90% !important;
        padding: 1em 2em;
        max-height: 90vh !important;
        background-color: $white-color !important;
        overflow-y: auto !important;
        overflow-x: hidden;
        box-shadow: 24 !important;
        outline: none !important;
        border-radius: 10px !important;
    
        .modal-close-btn {
            display: flex;
            justify-content: center;
            align-items: center;
            position: absolute;
            right: 0px;
            top: 0px;
            height: 20px;
            width: 20px;
            min-width: 0;
            padding: 20px;
            background-color: #F8F8F8;
            color: #CA3657;
            border-radius: 50%;
        }
    }
    .modal-table-container{
        .heading{
          margin: 10px 0 !important;
          margin-top: 20px !important;
        }
        // margin-top: 10px !important;
        // margin-bottom: 20px !important;
        .edit-detail-item{
          border-top:1px solid $border-gray-color;
          &:last-child{
            border-bottom:1px solid $border-gray-color;
          }
          
          .edit-item{
            
            // padding: 5px 20px;
            padding-top:5px;
            padding-bottom: 5px;
            padding-left: 20px;
            display: flex;
            justify-content: start;
            align-items: center;
          }
          .dark-bg{
            background-color: $very-light-gray-color;
          }
        }
      }
      .btn-container{
        // border: 1px solid red !important;
        // display: flex !important;
        // justify-content: center !important;
        // align-items: center !important;
        margin-top: 20px !important;
        margin-bottom: 10px !important;
      }

      @media only screen and (min-width: $tablet) {
        .modal-container {
          width: 50% !important;
          padding: 2em 4em;
      }
    }