.not-found-container{

    // min-height: 100vh;
 
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    h1{
        color: #4A4A4A;
text-align: center;
font-family: Montserrat;
font-size: 150px;
font-style: normal;
font-weight: 700;
line-height: 140.184%; /* 280.367px */
letter-spacing: 10px;
    }
    h5{
        color: #4A4A4A;
text-align: center;
font-family: Montserrat;
font-size: 30px;
font-style: normal;
font-weight: 500;
line-height: 140.184%; /* 70.092px */
letter-spacing: 2.5px;
    }
    p{
        color: #4A4A4A;
text-align: center;
font-family: Microsoft Sans Serif;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 140.184%; /* 28.037px */
letter-spacing: 1px;
    }
    .go-hme-btn{
        position: relative;
        left: -200px;
        // border: 4px solid red;
        bottom: 100px;
        .img{
            height: 300px;
            width: 100%;
            img{
                height: 100%;
                width: 100%;
            }
        }
        button{
            position: absolute;
            top: 57%;
            right: -10%;
            // width: 70%;
            max-height: 40px;
        }
    }
}


/* smartphones, portrait iPhone, portrait 480x320 phones (Android) */
@media only screen and (min-width: 0px) and (max-width: 320px) {
    .not-found-container {
        width: 90%;
      h1 {
        font-size: 80px;
      }
      h5 {
        font-size: 20px;
      }
      p {
        font-size: 14px;
      }
      .go-hme-btn {
        position: relative;
        left: 0;
        // border: 4px solid red;
        bottom: 0;
        margin-top: 20px;
        .img {
          height: 150px;
          width: 100%;
          img {
            height: 100%;
            width: 100%;
          }
        }
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        button {
          position: static;
          top: 0;
          right: 0;
          // float: right !important;
          // width: 70%;
          max-height: 40px;
        }
      }
    }
  }
  
  /* smartphones, Android phones, landscape iPhone */
  @media only screen and (min-width: 321px) and (max-width: 481px) {
      .not-found-container {
        width: 90%;
          h1 {
            font-size: 80px;
          }
          h5 {
            font-size: 20px;
          }
          p {
            font-size: 14px;
          }
          .go-hme-btn {
            position: relative;
            left: 0;
            // border: 4px solid red;
            bottom: 0;
            margin-top: 20px;
            .img {
              height: 150px;
              width: 100%;
              img {
                height: 100%;
                width: 100%;
              }
            }
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            button {
              position: static;
              top: 0;
              right: 0;
              // float: right !important;
              // width: 70%;
              max-height: 40px;
            }
          }
        }
  }
  
  /* portrait tablets, portrait iPad, e-readers (Nook/Kindle), landscape 800x480 phones (Android) */
  @media only screen and (min-width: 482px) and (max-width: 641px) {
      .not-found-container {
        width: 70%;
          h1 {
            font-size: 100px;
          }
          h5 {
            font-size: 25px;
          }
          p {
            font-size: 16px;
          }
          .go-hme-btn {
            position: relative;
            left: 0;
            // border: 4px solid red;
            bottom: 0;
            margin-top: 25px;
            .img {
              height: 150px;
              width: 100%;
              img {
                height: 100%;
                width: 100%;
              }
            }
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            button {
              position: static;
              top: 0;
              right: 0;
              // float: right !important;
              // width: 70%;
              max-height: 40px;
            }
          }
        }
  }
  
  /* tablet, landscape iPad, lo-res laptops ands desktops */
  @media only screen and (min-width: 642px) and (max-width: 961px) {
      .not-found-container {
          h1 {
            font-size: 100px;
          }
          h5 {
            font-size: 25px;
          }
          p {
            font-size: 16px;
          }
          .go-hme-btn {
            position: relative;
            left: 0;
            // border: 4px solid red;
            bottom: 0;
            margin-top: 25px;
            .img {
              height: 250px;
              width: 100%;
              img {
                height: 100%;
                width: 100%;
              }
            }
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            button {
              position: static;
              top: 0;
              right: 0;
              // float: right !important;
              // width: 70%;
              max-height: 40px;
            }
          }
        }
  }

