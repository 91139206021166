@import "./variables.scss";

.operator-container {
    // border: 2px solid red;

    .tabs-container {

        // border: 4px solid green !important;
        // .aircraft-card {
        //     padding: 12px;
        //     // padding-bottom: 12px;
        //     // padding-right: 12px;
        //     border-radius: 20px !important;
        //     background: $white-color !important;
        //     box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.25) !important;

        //     .card-img {
        //         // margin-bottom: 12px !important;
        //         // margin-right: 12px;
        //         // border: 2px solid black !important;
        //         height: 100%;
        //         width: 100%;

        //         img {
        //             object-fit: cover;
        //             height: 100%;
        //             width: 100%;
        //             border-radius: 10px;
        //         }

        //     }

            

        //     h6,
        //     p {
        //         margin-bottom: 8px;
        //     }

            

          

        //     .btn-container {
        //         display: flex;
        //         justify-content: flex-end;
        //         gap: 20px;
        //         align-items: center;
        //         margin-top: 0 !important;
        //         margin-bottom: 8px !important;
        //     }
        // }

        .operator-request-card {
            padding-bottom: 12px;
            padding-right: 12px;
            border-radius: 20px !important;
            background: $white-color !important;
            box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.25);



            h6,
            p {
                margin-bottom: 8px;
            }

            .approve-img {
                // border: 2px solid black !important;
                height: 60px;
                width: 100%;

                img {
                    object-fit: cover;
                    height: 100%;
                    width: 100%;
                    border-radius: 10px;
                }

            }

            .view-more-images {
                display: block;
                color: $secondary-color;
                font-family: Microsoft Sans Serif;
                font-size: 14px;
                text-align: right !important;
                margin-top: 10px !important;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                cursor: pointer;
                transition: ease-in-out 0.2s;

                &:hover {
                    opacity: 0.5;
                }

                // border: 1px solid red;
            }

            .view-full-details {
                cursor: pointer;
                text-align: right !important;
                text-decoration: underline;
                transition: ease-in-out 0.25s;

                &:hover {
                    opacity: 0.5;
                }
            }

            .btn-container {
                display: flex;
                justify-content: space-evenly;
                align-items: center;
            }
        }
    }



}

.aircraft-card {
    padding: 12px;
    // padding-bottom: 12px;
    // padding-right: 12px;
    border-radius: 20px !important;
    background: $white-color !important;
    box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.25) !important;

    .card-img {
        // margin-bottom: 12px !important;
        // margin-right: 12px;
        // border: 2px solid black !important;
        height: 100%;
        width: 100%;
        max-height: 135px;

        img {
            object-fit: cover;
            height: 100%;
            width: 100%;
            border-radius: 10px;
        }

    }

    

    h6,
    p {
        margin-bottom: 8px;
    }

    

  

    .btn-container {
        display: flex;
        justify-content: flex-end;
        gap: 20px;
        align-items: center;
        margin-top: 0 !important;
        margin-bottom: 8px !important;
    }
}


.operator-aircraft-container {

    // border: 2px solid red;
    .tabs-container {
        .aircraft-details-container {
            border-radius: 20px;
            background: #FFF;
            box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.25);

            .details-table-container {
                padding: 0 !important;
                border-radius: 20px !important;

                // border-bottom-left-radius: 20px;
                // border-bottom-right-radius: 20px;
                // background: #FFF;
                // box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);

                // border-radius: 20px !important;
                // background: #FFF !important;
                // box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25) !important;
                // padding: 0 !important;
                .detail-item {
                    border-bottom: 1px solid $dark-gray-color;
                    //   border-left:1px solid $border-gray-color;
                    // border-right: 1px solid $border-gray-color;


                    // &:first-child {
                    //     border-top-left-radius: 20px;
                    //     border-top-right-radius: 20px;
                    //     background: #FFF;
                    //     box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
                    // }

                    &:last-child {
                        // border-bottom-left-radius: 20px;
                        // border-bottom-right-radius: 20px;
                        // background: #FFF;
                        // box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
                        // border-bottom: 1px solid $border-gray-color;
                        border-bottom: none;
                    }

                   

                    .item {
                        // border-left: 1px solid $border-gray-color;
                        // border:1px solid red;

                        // border-radius: 20px;
                        // background-color: #fff;
                        // background-color: red;
                        // padding: 5px 20px;
                        min-height: 70px;
                        padding: 0 20px;
                        // padding-top: 20px;
                        // padding-bottom: 20px;
                        // padding-left: 20px;
                        display: flex;
                        justify-content: start;
                        align-items: center;
                        position: relative;

                       

                        span {
                            color: $dark-gray-color;
                            text-align: center;
                            font-family: Microsoft Sans Serif;
                            font-size: 14px;
                            position: absolute;
                            bottom: 5px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: normal;
                        }

                    }

                }
            }
        }
    }
}

.request-cancel-modal {
    // padding: 4em;

    // border-radius: 20px !important;
    background: #F5F5F5 !important;
    box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.25) !important;

    h6 {
        margin-bottom: 20px;
    }

    p {
        margin-bottom: 10px;
    }

    .reason-box {
        padding: 15px;
        border-radius: 10px;
        background: $white-color;
        box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.25);
    }

    .btn-container {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 20px;
    }
}

.confirm-request-modal {
    .btn-container {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 10px;
        margin: 0 !important;
    }
}

.images-modal {
    .img-card {
        height: 150px;
        width: 100%;

        img {
            height: 100%;
            width: 100%;
            object-fit: cover;
            border-radius: 10px;
            object-fit: cover;

        }

    }

}

.assign-aircraft-modal {
    h5 {
        color: $dark-gray-color;
        font-family: Montserrat;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: 0.24px;
        // margin-top: 20px;
    }

    .select-box {
        padding: 8px 15px;
        border-radius: 10px;
        border: 0.1px solid #808080;
        background: #FFF;
        // box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.25);
    }

    .call-sign-input {
        border-radius: 10px;
        padding: 7px 15px;
        border: 1px solid #808080;
        background: #FFF;
    }

    .btn-container {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 20px;
        
    }

    p {
        color: $dark-gray-color;
        font-family: Microsoft Sans Serif;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: 0.24px;
        margin-bottom: 25px;
    }

    .upload-image-container {
        // height: 200px;
        border-radius: 10px;
        border: 1.5px dashed #4A4A4A;

        .center {
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .btn {
            border-radius: 10px;
            border: 1px solid #808080;
            background: #FFF;
            color: $dark-gray-color;
        }

        .images-container {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 15px;
            margin-bottom: 30px;

            .card-img {
                height: 80px;
                width: 80px;

                img {
                    height: 100%;
                    width: 100%;
                    object-fit: cover;
                    border-radius: 5px;
                    // border: 1px solid red;
                }
            }

            .more-imgs {
                position: relative;

                img {
                    opacity: 0.4;

                }

                span {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    color: $dark-gray-color;
                    font-family: Microsoft Sans Serif;
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                    letter-spacing: 0.2px;
                }
            }
        }
    }
}

.edit-availability-modal {

    .date-input,
    .select-box {
        border-radius: 10px;
        border: 1px solid #4A4A4A;
        background: #FFF;
        padding: 5px 10px;
    }

    p {
        color: #696969;
        text-align: right;
        font-family: Microsoft Sans Serif;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        margin-top: 10px;
        line-height: normal;

        span {
            color: $secondary-color;
            transition: ease-in-out 0.2s;

            &:hover {
                opacity: 0.5;
            }
        }
    }

    .btn-container {
        display: flex;
        justify-content: center;
        align-items: center;
    }

}