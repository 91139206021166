@import "./variables.scss";
.login-container{
    position: relative !important;
    height: 100vh !important;
    width: 100vw !important;
    background-color:  $very-light-gray-color!important;
    .form-container{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        width: 300px;
        padding: 2em;
        box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
        background-color: $white-color;
        border-radius: 10px;
      display: flex;
      flex-direction: column;
      gap: 40px;
      .logo{
        display: flex;
        justify-content: center;
        align-items: center;
      }

        
        .heading{
           font-size: 30px;
           text-align: center;
        }
        .btn{
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
}



