


.generate-quotation-modal{
    // border: 4px solid red;

    .itinerary-details-card{
        max-height: 180px ;
        overflow-y: auto;
        .quotation-details {
            padding-left: 5px;
            padding-right: 5px;
            padding-bottom: 20px;
            margin-top: 10px;
            border-bottom: 1px solid #808080;
            &:last-child{
                border-bottom: none;
            }
            .grid-item {
                
                display: flex;
                align-items: center;
                justify-content: space-between;
        
                p {
                    color: $dark-gray-color;
                    font-family: Microsoft Sans Serif;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    // line-height: 150%; /* 36px */
                    letter-spacing: 1.2px;
                }
        
                h6 {
                    color: $dark-gray-color;
                    // text-align: right;
                    font-family: Microsoft Sans Serif;
                    font-size: 20px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 150%;
                    /* 60px */
                    letter-spacing: 1px;
                }
        
                .btn {
                    border-radius: 30px;
                    padding: 3px;
                    background: #FFF;
                    box-shadow: 0px 2px 4px 2px rgba(0, 0, 0, 0.25);
                    text-align: center;
                }
            }
        }

    }
   
    .select-box {
        padding: 8px 15px;
        border-radius: 10px;
        border: 0.1px solid #808080;
        background: #FFF;
        // box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.25);
    }
    .btn-container {
        display: flex;
        justify-content: center;
        gap: 20px;
        align-items: center;
        margin-top: 0 !important;
        margin-bottom: 8px !important;
    }
}