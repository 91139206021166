@import "../../style/sass/variables.scss";




.editModalStyle{
    width: 90%;    
}

.modalStyling{
    // padding: 20px 20px 40px 20px;
    // width: 90%;

    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;

    .imageInputContainer{
        // border-radius: 10px;
        // width: 100%;
        // height: 100%;
        // position: relative;
        // margin-top: 30px;
        border-radius: 20px;
        border:2px dotted #4A4A4A;
        padding: 20px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 10px;
       

        label{
            cursor: pointer;
            font-family: Microsoft Sans Serif, sans-serif;
            color: $secondary-color !important;
            transition: all 0.5s;
            &:hover{
                opacity: 0.5;
            }
        }
    }
  }
  .bannerUploadBtn{
   
    display: flex;
    gap: 5px;
    align-items: center;
  
}
@media only screen and (min-width: $tablet) {
    .modalStyling{
        // width: 50%;

        .imageInputContainer{
            // margin-top: 50px;
            padding: 40px;
            
        }
      }
}

@media only screen and (min-width: $laptop) {
    .editModalStyle{
        width: 85% !important;    
    }
    .modalStyling{
        // width: 60%;

        .imageInputContainer{
            padding: 50px;
        }
      }
}